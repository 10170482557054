<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="CLOSED_SPACE_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="closedSpaceTypeCd"
            label="밀폐공간 종류"
            v-model="searchParam.closedSpaceTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card title="밀폐공간 배치도" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="추가" v-if="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="삭제" v-if="editable && tempMapDetails.length > 0" icon="remove" @btnClicked="removeClick"/>
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="data.maps"
            mappingType="POST"
            label="저장" 
            icon="save" 
            @beforeAction="saveClick"
            @btnCallback="saveCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container" ref="carousel">
            <q-carousel
              v-if="data.maps && data.maps.length > 0"
              class="map-carousel"
              control-type="regular"
              control-color="grey-6"
              animated
              arrows
              v-model="data.slide"
              infinite
              @transition="transition"
            >
              <q-carousel-slide 
                v-for="(map, idx) in data.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc"
                >
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="q-img__content absolute-full"> 
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :resizable="false"
                      :parent="true"
                      :draggable="true"
                      :x="item.locationXcoordinate"
                      :y="item.locationYcoordinate"
                      :w="30" :h="30"
                      :grid="[30, 30]"
                      @dragging="(x, y) => onDrag(item, x, y)"
                    >
                      <!-- {{idx + 1}} -->
                      <q-img fit="scale-down"
                        style="bottom: 5px;" :contain="true">
                      </q-img>
                      <!-- <q-icon name="check" color="blue" class="blinking" /> -->
                          <!-- top: getTop(item), -->
                      <div class="markImage-abbreviation-parent"
                        :style="{ 
                          width: '220px', 
                          left: getLeft(item),
                        }">
                        <div class="markImage-abbreviation" 
                          @mouseover="() => { item.isHover = true }"
                          @mouseleave="() => { item.isHover = false }">
                          <!-- <transition name="mark-list" tag="div">
                            <div v-if="!item.isHover" class="markImage-tran">
                              <q-banner
                                dense 
                                class="markImage-abbreviation-banner">
                                <q-icon name="report_problem" class="text-blue" />
                                <div>
                                  {{item.processName}}
                                </div>
                              </q-banner>
                            </div>
                          </transition> -->
                          <transition name="mark-list" tag="div">
                            <div>
                              <q-banner
                                dense 
                                :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                <template v-slot:avatar>
                                  <q-icon name="warning" class="text-red" size='xs' />
                                </template>
                                <div class="caption-header">
                                  {{item.heaClosedSpaceName}}
                                </div>
                                <!-- <q-btn class="q-ml-sm" outline round color="red" size="9px" label="" icon="add"/> -->
                                <div class="caption-work">
                                  {{ '종류: ' + getSpaceTypeName(item.closedSpaceTypeCd) }}
                                  <br>
                                </div>
                              </q-banner>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </VueDraggableResizable>
                  </div>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="등록된 지도가 없습니다."></el-empty>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
            <c-table
              ref="table"
              title="밀폐공간 목록"
              :isTitle="false"
              selection="multiple"
              :columns="grid.columns"
              :data="tempMapDetails"
              rowKey="heaClosedSpaceId"
              @table-data-change="tableDataChange"
            >
            </c-table>
          </div>
        </template>
      </template>
    </c-card>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'closed-space-map',
  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        closedSpaceTypeCd: null,
      },
      mapMessage: '',
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      codeNames: [],
      tempMapDetails: [],
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.835
    },
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.closedspaceMap.list.url
      this.saveUrl = transactionConfig.hea.closedspaceMap.save.url;
      this.deleteUrl = transactionConfig.hea.closedspaceMap.delete.url;

      this.$comm.getComboItems('CLOSED_SPACE_TYPE_CD').then(_result => {
        this.codeNames = _result
        this.grid.columns = [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
            type: 'plant'
          },
          {
            name: 'closedSpaceTypeCd',
            field: 'closedSpaceTypeCd',
            label: '종류',
            align: 'center',
            sortable: true,
            type: 'select',
            comboItems: _result,
            style: 'width: 250px',
          },
          {
            name: 'heaClosedSpaceName',
            field: 'heaClosedSpaceName',
            label: '밀폐공간명',
            align: 'left',
            sortable: true,
            type: 'text',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '설명',
            align: 'left',
            sortable: false,
            type: 'text',
            style: 'width: 200px',
          },
        ];
      });

      this.getList();
    },
    getList() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.data.maps = _result.data
          if (this.data.maps && this.data.maps.length > 0) {
            this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
          } else {
            this.tempMapDetails = [];
          }
          this.$refs['carousel'].style.setProperty("--map-ratio", this.data.maps[0].ratio);
        } else {
          this.tempMapDetails = [];
          this.data = {
            slide: 0,
            mapSrc: require('@/assets/images/no-image.png'),
            fullscreen: false,
            maps: [],
          }
        }
      },);
    },
    transition() {   
      this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
    },
    onItemClick() {
      this.data.maps[this.data.slide].maps.push({
        editFlag: 'C',
        heaClosedSpaceId: uid(),  // 밀폐공간 번호
        plantCd: this.searchParam.plantCd,  // 사업장 코드
        heaClosedSpaceName: '',  // 밀폐공간명
        closedSpaceTypeCd: null,  // 밀폐공간 종류코드
        remark: '',  // 밀폐공간 설명
        locationXcoordinate: 0,  // 작업위치 X좌표
        locationYcoordinate: 0,  // 작업위치 Y좌표
        sopMapId: this.data.maps[this.data.slide].sopMapId,
      })
      this.data.maps[this.data.slide].mapDetails.push({
        editFlag: 'C',
        heaClosedSpaceId: uid(),  // 밀폐공간 번호
        plantCd: this.searchParam.plantCd,  // 사업장 코드
        heaClosedSpaceName: '',  // 밀폐공간명
        closedSpaceTypeCd: null,  // 밀폐공간 종류코드
        remark: '',  // 밀폐공간 설명
        locationXcoordinate: 0,  // 작업위치 X좌표
        locationYcoordinate: 0,  // 작업위치 Y좌표
        sopMapId: this.data.maps[this.data.slide].sopMapId,
      })
      this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
    },
    onDrag(item, x, y) {
      this.realKey = item.heaClosedSpaceId

      this.$_.forEach(this.data.maps[this.data.slide].maps, _item => {
        if (_item.heaClosedSpaceId === item.heaClosedSpaceId) {
          if (_item.editFlag !== 'C') {
            _item.editFlag = 'U'
            _item.chgUserId = this.$store.getters.user.userId
          }
        }
      })
      this.$set(item, 'locationXcoordinate', x)
      this.$set(item, 'locationYcoordinate', y)
    },
    getWidth() {
      let width = 0;
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + 210;
      let detailLocation = item.locationXcoordinate + 210 
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 29;
      let calLocation = item.locationYcoordinate;
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return top + 'px'
    },
    saveClick() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.data.maps, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
          });
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeClick() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.$_.forEach(this.data.maps, _item => {
                _item.maps = this.$_.reject(_item.maps, {heaClosedSpaceId: item.heaClosedSpaceId})
                _item.mapDetails = this.$_.reject(_item.mapDetails, {heaClosedSpaceId: item.heaClosedSpaceId})
              })
            })
            this.tempMapDetails = this.data.maps[this.data.slide].mapDetails;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    tableDataChange() {
      this.data.maps[this.data.slide].mapDetails = this.tempMapDetails;
      this.data.maps[this.data.slide].maps = this.tempMapDetails;
    },
    getSpaceTypeName(_cd) {
      let list = this.$_.filter(this.codeNames, { code: _cd });
      if (list.length == 1) {
        return list[0].codeName;
      } else {
        return '';
      }
    }
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 
// .vdr 
//   border: 3px dashed #000

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: blue
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      // cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        padding: 1px !important
.caption-work
  font-size: 0.5em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.75em
  font-style: italic
  white-space: normal
  color: #555

.map-carousel
  height: var(--map-height) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>

<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>